@media only screen and (max-width: $mobile-breakpoint) {
  .banner__menu-toggle.button--inline,
  .banner__menu-toggle.button--inline:hover {
    display: flex;
  }

  .sidebar {
    height: auto;
    position: fixed;
    width: 100%;
    top: 0;
    padding-bottom: 1rem;
    z-index: 100;
    padding-top: calc(#{$sidebar-padding} + #{$banner-height-scrolled});
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
    transition: transform 0.25s ease-in;
    &.sidebar--hide {
      transform: translate3d(0, -100%, 0);
      box-shadow: none;
    }
  }

  #root {
    display: block;
  }

  #root > main {
    left: 0;
    width: 100%;
    top: 0;
    &.main--show-mobile-sidebar {
      top: $banner-height-mobile;
    }
    &.main--show-mobile-sidebar:after {
      // Overlay behind sidebar
      background: rgba(0, 0, 0, 0.5);
      content: "";
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 15;
    }
    & > section {
      padding: 2rem;
      position: relative;
    }
  }
  #root > .alert + main > section {
    padding: 2rem;
  }
  

  .alert--full-width-show-mobile-sidebar {
    position: relative;
    top: $banner-height-mobile;
  }
  .alert--full-width-show-mobile-sidebar:after {
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    pointer-events: none;
  }
  .alert--full-width {
    padding: 2rem;
    left: 0;
    width: 100%;
  }
}
