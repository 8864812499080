.tab-nav {
    display: flex;
    border-bottom: 2px solid $color-rule;
    margin-bottom: 2rem;
}

.tab-nav__tab {
    flex-grow: 1;
    margin: 0 0.2rem;
    padding: 1rem 2rem 0.5rem;;
    text-align: center;
    background: $color-gray-dark;
    text-decoration: none;
    border-radius: 0;
    border: none;
    color: white;
    text-transform: none;
    line-height: 1;
    font-size: 1.6rem;
    
    letter-spacing: 0;
    height: auto;
    &:hover {
        color: white;
        background: $color-secondary;
    }
}

.tab-nav__tab--active {
    background: $color-primary;
    color: white;
    &:hover {
        background: $color-primary;
        cursor: default;
    }
}

@media only screen and (max-width: 800px) {
    .tab-nav {
        flex-direction: column;
        border-bottom: none;
    }
    .tab-nav__tab:not(:last-child) {
        margin-bottom: 0.1rem;
    }
}