// Typography
// ––––––––––––––––––––––––––––––––––––––––––––––––––

b,
strong {
  font-weight: bold;
}
p {
  margin-top: 0;
  svg {
    display: inline-block;
    width: 1em;
    position: relative;
    top: 0.2rem;
  }
}
p.paragraph--error {
  color: $color-error;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: -0.1rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-family: "Gotham SSm A", "Gotham SSm B", "Poppins", "Helvetica Neue", Helvetica,
    Arial, sans-serif;

  & + p,
  & + ul,
  & + ol {
    // margin-top: -1.5rem;
  }
}

h1 {
  font-size: 4.6rem;
  line-height: 1.2;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}

h3 {
  font-size: 2.8rem;
  line-height: 1.3;
}

h4 {
  font-size: 2.2rem;
  letter-spacing: -0.08rem;
  line-height: 1.35;

  &.deck {
    font-weight: 300;
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
}

h5 {
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  & + p,
  & + ul {
    margin-top: 0;
  }
}
th,
td,
p,
li {
  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
}

.link {
  color: $color-primary;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
  h4.deck {
    font-size: 1.8rem;
  }
}
