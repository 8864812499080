.banner {
  align-items: center;
  background-color: $color-primary;
  display: flex;
  height: $banner-height;
  padding: 0.25rem 2rem 0.5rem;
  position: fixed;
  width: 100%;
  z-index: 200;
  .banner__h1 {
    color: white;
    display: inline-block;
    flex-grow: 1;
    font-size: 4rem;
    font-style: italic;
    font-weight: 800;
    letter-spacing: -0.15rem;
    line-height: 1;
    margin: 0;
    text-shadow: 0.25rem 0 0.5rem rgba(0, 0, 0, 0.2);
    transition: font-size 0.25s;
  }
  a {
    color: white;
    text-decoration: none;
  }
  &.banner--homepage a {
    cursor: default;
  }
  //Diaganol lines
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cpattern%20id%3D%22a%22%20patternUnits%3D%22userSpaceOnUse%22%20width%3D%225%22%20height%3D%225%22%20patternTransform%3D%22rotate(45)%22%3E%3Cpath%20stroke%3D%22%23000%22%20d%3D%22M1%200v5%22%2F%3E%3C%2Fpattern%3E%3C%2Fdefs%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22url(%23a)%22%2F%3E%3C%2Fsvg%3E");
    content: "";
    opacity: 0.2;
    z-index: 5;
  }
  * {
    z-index: 10;
  }
}

.banner__logo {
  height: 2rem;
  width: 4.5rem;
  display: inline-block;
  position: relative;
  top: -0.8rem;
  transition: all 0.25s;
  margin-right: 0.5rem;
  svg {
    float: left;
    filter: drop-shadow(0 0.3rem 0.2rem rgba(0, 0, 0, 0.4));
  }
}

.banner__tagline {
  color: white;
  display: inline-block;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 300;
  margin-left: 0.5rem;
  text-shadow: none;
  transition: all 0.25s;
  letter-spacing: 0;
}

.banner--scrolled {
  height: $banner-height-scrolled;
  &.banner {
    padding-bottom: 0.1rem;
  }
  .banner__h1 {
    font-size: 2rem;
    letter-spacing: -0.075rem;
    text-shadow: 0.1rem 0 0.25rem rgba(0, 0, 0, 0.1);
    line-height: 1;
  }
  .banner__logo {
    height: 0;
    width: 0;
    opacity: 0;
  }
  .banner__tagline {
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }
}

.banner--dev {
  .banner__h1--env {
    color: yellow;
    font-weight: 400;
  }
}

.banner--staging {
  .banner__h1--env {
    color: red;
    font-weight: 400;
  }
}

.logo__group {
  isolation: isolate;
}
.logo__gradient {
  opacity: 0.6;
  mix-blend-mode: multiply;
  fill: url(#a);
}

.banner__menu-toggle.button--inline,
.banner__menu-toggle.button--inline:hover {
  // border-bottom: 1px solid white;
  border-radius: 0;
  color: white;
  display: none;
  font-size: 1.2rem;
  height: 2rem;
  line-height: 1.2rem;
  padding: 0;
  position: relative;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  span {
    display: block;
    position: relative;
    top: 0.6rem;
  }
  svg {
    width: 1.6rem;
    display: inline-block;
    margin-left: 0.25rem;
  }
}

.banner__menu-toggle.button--inline.banner__menu-toggle--hide,
.banner__menu-toggle.button--inline.banner__menu-toggle--hide:hover {
 position: relative;
 top: -0.1rem;
  font-size: 2rem;
  height: 2.5rem;
  line-height: 1;
  font-weight:200;
}
// .banner__menu-toggle--show:after {
//   content: "";
//   display: block;
//   border-top: 1px solid white;
//   border-bottom: 1px solid white;
//   height: 6px;
//   margin-bottom: 3px;
//   width: 100%;
//   position: relative;
//   top: 6px;
// }

@media only screen and (max-width: $mobile-breakpoint) {
  .banner {
    position: relative;
    height: $banner-height-mobile !important;
    &.banner--show-mobile-sidebar {
      position: fixed;
    }
  }
  .banner__tagline {
    display: block;
    margin-left: 0;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }
  .banner__logo,
  .banner__h1--env {
    display: none;
  }
  .banner .banner__h1 {
    font-size: 2rem;
    letter-spacing: -0.075rem;
    line-height: 1;
    text-shadow: 0.1rem 0 0.25rem rgba(0, 0, 0, 0.1);
    text-shadow: none;

  }
}
