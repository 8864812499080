
// Link
// ––––––––––––––––––––––––––––––––––––––––––––––––––

a
	color: $color-primary
	text-decoration: underline
	touch-action: manipulation

	&:focus,
	&:hover
		color: $color-primary
