// SYNC THESE WITH styleVariables.ts

$mobile-breakpoint: 900px;

$banner-height: 4.6rem;
$banner-height-mobile: 3.8rem;
$banner-height-scrolled: 3.2rem;
$banner-margin: 2rem;

$footer-height: 1.6rem;
$footer-margin: 2rem;

$main-padding: 4rem;

$color-body: #3a393a;
$color-body-light: #505050;
$color-body-lighter: #999;

$color-rule: #a5a5a5;
$color-border: $color-rule;

$color-primary: #0074d9;
$color-primary-light: lighten($color-primary, 20%);
$color-primary-lighter: lighten($color-primary, 50%);
$color-primary-panel: rgba($color-primary, 0.1);

$color-secondary: #ee2323;
$color-secondary-light: lighten($color-secondary, 20%);
$color-secondary-lighter: lighten($color-secondary, 50%);

$color-panel: #fafafa;
$color-panel-dark-mono: #666;
$color-panel-dark: darken($color-primary, 20%);

$color-cancel: #dd0606;
$color-cancel-light: rgba($color-cancel, 0.8);
$color-cancel-lighter: rgba($color-cancel, 0.5);
$color-cancel-panel: rgba($color-cancel, 0.1);

$color-wap: #fd9d02;

$color-error: $color-cancel;
$color-error-light: $color-cancel-light;
$color-error-panel: $color-cancel-panel;

$color-success: #2b802b;
$color-success-light: rgba($color-success, 0.8);
$color-success-lighter: rgba($color-success, 0.5);
$color-success-panel: rgba($color-success, 0.1);

$color-gray-dark: #222;

$color-neutral-1: #ccc;

$color-neutral-2: #aaa;

$color-neutral-3: $color-body-lighter;

$color-neutral-4: $color-body-light;

$font-small: 1rem;
$font-display: "Gotham SSm A", "Gotham SSm B", "Poppins", "Helvetica Neue", Helvetica,
  Arial, sans-serif;
$font-sans: "Gotham SSm A", "Gotham SSm B", "Poppins", "Helvetica Neue", Helvetica, Arial,
  sans-serif;

$base-font-size: 1.6rem;
