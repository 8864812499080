$gutter: 4rem;

.player_details {
  li {
    border-radius: 0.5rem;
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1;
    list-style: none;
    margin-bottom: 0;
    margin-right: 1rem;
    padding-left: 0;
    position: relative;
    text-indent: 0;
    .player__pill {
      background: $color-primary;
      border-radius: 1rem;
      color: white;
      display: block;
      padding: 0.4rem 1rem;
      text-decoration: none;
      white-space: nowrap;
      button.pill__edit {
        background: none;
        border: 0;
        display: inline-block;
        font-size: 1rem;
        height: 1rem;
        line-height: 0;
        margin: 0 0 0 0.4rem;
        padding: 0;
        position: relative;
        top: -0.4rem;
        vertical-align: text-bottom;
        width: 1rem;
        &:hover {
          background: none;
        }
        svg {
          height: 1.4rem;
          width: 1.4rem;
        }
      }
    }

    .player__pill {
      background: $color-success;
    }
    .player__pill--primary-color {
      background: $color-primary;
    }
    a.player__pill {
      background: $color-primary;
    }
    .player__pill--trophy {
      background: white;
      border: 1px solid $color-primary;
      color: $color-primary;
      padding: 0.3rem 1rem 0.3rem 2.5rem;
      position: relative;
      svg {
        position: absolute;
        top: 0.1rem;
        left: 0.5rem;
      }
    }
    a.player__pill--wap {
      background: $color-wap;
    }
    a:hover {
      background: $color-primary-light;
    }
    .player__pill--editing {
      background: none;
      border-bottom: 1px solid $color-rule;
      border-radius: 0;
      padding: 0 0 0.2rem 0;
      form {
        margin: 0;
      }
      input {
        border: none;
        margin: 0;
        padding: 0;
        width: 15rem;
        font-size: 1.4rem;
        height: auto;
      }
      input.pill__input--wide {
        width: 25rem;
      }
    }
  }
}

button.pill__save {
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  margin: 0;
  padding: 0.2rem 0.4rem;
  border-radius: 0.2rem;
  background: none;
  border: 0.1rem solid $color-success;
  color: $color-success;
  position: relative;
  top: -0.2rem;
  &:hover {
    background: $color-success;
    color: white;
    border: 0.1rem solid $color-success;
  }
}

button.player__name-edit {
  background-color: transparent;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;

  &:hover,
  &:active {
    background-color: transparent;
    border: none;
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
}

.player__name-edit-form {
  border-bottom: 1px solid $color-rule;
  padding: 0 0 0.4rem 0;
  position: relative;
  input {
    border: 0;
    font-size: 3.5rem;
    height: auto;
    margin: 0 0.5rem 0 0;
    max-width: none;
    padding: 0;
    width: calc(100% - 5rem);
  }
  button.pill__save {
    background: $color-success;
    color: white;
    position: absolute;
    right: 0;
    bottom: 0.5rem;
    width: 10rem;
    font-size: 1.5rem;

    &:hover {
      background: $color-success-light;
      border: 1px solid $color-success-light;
    }
  }
}

.preference--checkbox {
  margin-bottom: 0.5rem;
  position: relative;

  label {
    z-index: 10;
    opacity: 1;
    position: relative;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0.5s;
  }

  label.preference-label__change {
    opacity: 0;
    padding-left: 2.5rem;
    position: absolute;
    top: 0;
    z-index: 0;
  }

  &.active {
    label {
      opacity: 0;
      z-index: 0;
    }
    label.preference-label__change {
      opacity: 1;
      z-index: 10;
    }
  }
}

.player__reminders {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
  .player__name-edit-form {
    border-bottom: 0;
    padding: 0;
    margin-bottom: 2rem;
    input {
      padding-bottom: 0.4rem;
      border-bottom: 1px solid $color-rule;
      width: 100%;
      margin: 0 0 1rem;
    }
    button {
      position: relative;
      display: block;
      width: 100%;
      height: 3rem;
    }
  }
}

#score-history {
  margin-top: 1rem;
}

.player__label {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  border-bottom: 1px solid $color-rule;
  margin-bottom: 0.5rem;

  &--center {
    text-align: center;
  }

  & + * {
    margin-top: 0;
  }
}

.player__label--sub {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 0;
  letter-spacing: 0;
}

.player__label--result {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  border-bottom: 0.1rem solid $color-rule;
}

.player__stat {
  font-weight: 800;
  font-size: 4rem;
  line-height: 1;
  margin-top: 0;
  .player__stat-memo {
    font-size: 1.2rem;
    font-weight: 300;
    display: block;
  }
}

.player__explainer {
  font-size: 1.2rem;
  margin-top: 0;
  line-height: normal;
}

.player__list {
  margin-top: 0;
  list-style: none;
}

.player__list--ruled {
  li:not(:last-child) {
    border-bottom: 1px dotted $color-rule;
    padding-bottom: 0.5rem;
  }
}

.player__list-item {
  display: flex;
  font-size: 1.2rem;
  line-height: normal;
  margin-bottom: 0.5rem;
  padding: 0;
  text-indent: 0;
  span.player__list-item-rank {
    width: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.2rem;
    text-align: right;
  }
  span.player__list-item-text {
    flex-grow: 1;
  }
  span.player__list-item-figure {
    flex-grow: 1;
    flex-shrink: 0;
    text-align: right;
    width: 4rem;
  }
}

.player__stat--points {
  font-size: 8rem;
}

.player__winner-loser-item {
  display: flex;
  text-indent: 0;
  padding-left: 0;
}
.player__winner-loser-amount {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 2rem;
  text-align: right;
  width: 6rem;
}

.player__result-chart {
  display: flex;
  margin-bottom: 1rem;
}
.player__result-chart-label {
  font-size: 1.2rem;
  width: 8rem;
  line-height: 1;
  position: relative;
  top: 0.2rem;
}

.bar__outer {
  width: 100%;
  height: 1.5rem;
  background-color: $color-panel;
  border-right: 0.1rem solid $color-primary;
  margin-bottom: 0.5rem;
}
.bar__outer--secondary {
  border-right: 0.1rem solid $color-success;
}
.bar__inner {
  background-color: $color-primary;
  height: 100%;
}
.bar__inner--secondary {
  background-color: $color-success;
}

.player__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  p {
    font-size: 1.2rem;
    line-height: normal;
  }
}
.player__row--no-margin-bottom {
  margin-bottom: 0 !important;
}

div[class^="player__col--"] {
  & *:last-child:not(h2) {
    margin-bottom: 0;
  }
}

.player__col--100 {
  width: 100%;
}

.player__col--25 {
  width: calc(25% - (#{$gutter} * 3 / 4));
  margin-right: $gutter;

  &:nth-child(4n) {
    margin-right: 0;
  }
}
.player__col--50 {
  width: calc(50% - (#{$gutter} * 1 / 2));
  margin-right: $gutter;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.player-contest {
  margin-bottom: 1rem;
}

.player-contest-bar__outer {
  height: 1.5rem;
  margin-bottom: 0.25rem;
  position: relative;

  .player-contest-bar__label {
    position: absolute;
    top: 0.15rem;
    white-space: nowrap;
    width: 45%;
  }

  .player-contest-bar__bar {
    height: 1.5rem;
    position: absolute;
  }

  &.player-contest-bar__outer--gain {
    .player-contest-bar__label {
      right: calc(50% + 0.5rem);
      text-align: right;

      &::before {
        content: "+";
      }
    }

    .player-contest-bar__bar {
      background-color: $color-success;
      left: 50%;
    }
  }

  &.player-contest-bar__outer--loss {
    .player-contest-bar__label {
      left: calc(50% + 0.25rem);
    }

    .player-contest-bar__bar {
      background-color: $color-cancel;
      right: 50%;
    }
  }
}

.player-contest-bar__outer--roi.player-contest-bar__outer--gain {
  .player-contest-bar__bar {
    background-color: $color-primary;
  }
}

@media only screen and (max-width: 700px) {
  .player__col--25 {
    width: calc(50% - (#{$gutter} * 1 / 2));
    margin-right: $gutter;

    &:nth-child(4n) {
      margin-right: $gutter;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:not(:nth-last-child(-n + 2)) {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 400px) {
  .player__row {
    margin-bottom: 2rem;
  }
  .player__col--25,
  .player__col--50 {
    width: 100%;
    margin-right: 0;

    &:nth-child(4n),
    &:nth-child(2n) {
      margin-right: 0;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  .bet-history__outer {
    margin-top: 2rem;
  }
}
