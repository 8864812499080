.proposition__label {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  a {
    text-decoration: none;
  }
}

.proposition__content {
  display: flex;
  width: 100%;
}

.proposition__form {
  margin-bottom: 0;
}

.proposition__meta {
  border-left: 1px solid $color-rule;
  flex-basis: 30rem;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 1.2rem;
  line-height: normal;
  margin-left: 2rem;
  max-width: 30rem;
  min-width: 20rem;
  order: 2;
  padding-left: 2rem;

  a {
    text-decoration: none;
    // color: $color-body;
    &:hover {
      color: $color-primary;
    }
  }

  h6 {
    border-bottom: 1px solid $color-rule;
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 0.3rem;
    margin-top: 1.6rem;
    padding-bottom: 0.3rem;
    text-transform: uppercase;
  }
  & > h6:first-child {
    margin-top: 0;
  }
  p {
    margin-bottom: 0;
  }
  p + p {
    margin-top: 1.2rem;
  }
  ul {
    margin-bottom: 0;
  }
}
.proposition__meta-h6 {
  border-bottom: 1px solid $color-rule;
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 0.3rem;
  margin-top: 4rem;
  padding-bottom: 0.3rem;
  text-transform: uppercase;
}

.proposition__meta-p--with-icon {
  // display: flex;
  svg {
    width: 1.6rem;
    height: 1.2rem;
    margin-right: 0.25rem;
    position: relative;
    top: 0.1rem;
  }
}

.proposition__meta-row {
  display: flex;
}
.proposition__meta-col {
  width: calc(50% - 1rem);
  &:first-child {
    margin-right: 2rem;
  }
}

.proposition__related-races-item {
  display: flex;
  list-style: none;
  padding: 0;
  text-indent: 0;
  svg {
    height: 1em;
    width: 1em;
    position: relative;
    top: 0.1rem;
    margin-right: 0.3rem;
    flex-grow: 0;
    flex-shrink: 0;
  }
  a {
    display: block;
    flex-grow: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.winner-loser-list {
  list-style: none;

  a {
    text-decoration: none;
    color: $color-primary;
  }
}

.proposition__options {
  flex-grow: 1;
}

.button--bet {
  font-size: 2rem;
  margin-bottom: 0;
  margin-top: 2rem;
  width: 100%;
}

.bet-messages {
  background-color: $color-primary;
  color: white;
  margin: 2rem 0;
  padding: 2rem;
  li:last-child {
    margin-bottom: 0;
  }
}

.bet-message {
  line-height: normal;
  list-style: none;
  display: flex;
  text-indent: 0;
  padding-left: 0;
  span {
    display: block;
  }
  span.bet-message__icon {
    flex-grow: 0;
    flex-shrink: 0;
    height: 1.6rem;
    margin-right: 1rem;
    position: relative;
    top: 0.1rem;
    width: 1.6rem;
  }
  span.bet-message__text {
    flex-grow: 1;
  }
  &--error {
    font-weight: 700;
  }
  &--suggestion {
    line-height: normal;
    list-style: none;
    margin-top: 2rem;

    a {
      color: white;
      text-decoration: none;
    }
  }
  &--first-bet {
    display: block;
    font-size: 2rem;
    padding-left: 0;
    text-indent: 0;
  }
  &--social-share {
    display: block;
    padding-left: 0;
    text-indent: 0;
    svg {
      position: relative;
      line-height: 0;
      top: 0.5rem;
      width: 2.2rem;
      height: 2.2rem;

      &:hover {
        filter: drop-shadow(0 0 0.1rem rgba(255, 255, 255, 1));
      }
      &:active {
        top: 0.6rem;
        filter: none;
      }
    }
  }
}

.alert--odds-explainer {
  background: $color-primary;
  display: flex;
  font-size: 1.4rem;
  margin-top: 1rem;
  opacity: 0.1;
  transition: 0.2s opacity ease-out;
  svg {
    width: 2em;
    height: 2em;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1rem;
  }
  span {
    flex-grow: 1;
  }

  &.alert-enter-done {
    opacity: 1;
  }
}

.scratches__list {
  list-style: none;
  margin-bottom: 0;
}
.scratch {
  margin-bottom: 0;
  font-size: 1.4rem;
}
.scratch:not(:last-child) {
  margin-bottom: 1rem;
}
.scratch__reason {
  display: span;
  font-size: 1.2rem;
  font-style: italic;
}

@media only screen and (max-width: 1100px) {
  .proposition__content {
    flex-direction: column;
  }
  .proposition__meta {
    margin-left: 0;
    max-width: none;
    border: none;
    margin-bottom: 2rem;
    order: 1;
    padding-left: 0;
    width: 100%;
    flex-basis: 0;
  }
  .proposition__options {
    order: 2;
  }
  .proposition__related {
    display: none;
  }
}
