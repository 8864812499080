.contest__table {
  margin-bottom: 4rem;
}

.contest__proposition-list {
  column-count: 3;
  column-gap: 2rem;
  list-style: none;
}
.contest__proposition-item {
  display: inline-block;
  margin-bottom: 2rem;
  width: 100%;
  a {
    color: $color-body;
    text-decoration: none;
  }
}

@media only screen and (max-width: 700px) {
    .contest__proposition-list {
        column-count: 2;
    }
}

@media only screen and (max-width: 500px) {
    .contest__proposition-list {
        column-count: 1;
    }
}
