.resolution-card {
  padding-bottom: 0;

  .prop-card__question {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.resolution-card .prop-card__meta {
  position: relative;
  bottom: 0;
}

.resolution-list {
  list-style: none;
  margin: 0;
}

li.resolution-item {
  margin-bottom: 0.5rem;
}

.resolution-item__more {
  color: $color-primary;
  text-align: right;
}

.resolution-icon svg {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.4rem;
  position: relative;
  top: 0.1rem;
}
