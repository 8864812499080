.option {
  display: flex;
  padding: 1rem 0;
}

.option__header {
  background: white;
  display: flex;
  margin-top: -0.5rem;
  .option__odds,
  .option__bet-input,
  .option__main {
    @extend th;
    font-weight: 700;
    padding-top: 0;
    text-transform: uppercase;
  }
  .option__bet-input {
    padding-left: 2rem;
    width: 18rem;
  }
  .option__main {
    padding-left: 2rem;
  }
}
@supports (position: sticky) {
  .option__header {
    position: sticky;
    padding-top: 2rem;
    top: calc(#{$banner-height-scrolled});
    z-index: 100;
  }
}

.option__bet-input {
  flex-grow: 0;
  flex-shrink: 1;
}
.option:not(:last-of-type) {
  border-bottom: 1px solid $color-rule;
}

.option__odds {
  flex-grow: 0;
  flex-shrink: 0;
  font-family: $font-sans;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1;

  width: 10rem;
  overflow: visible;
  svg {
    height: 1rem;
    overflow: visible;
    display: inline;
  }
}

.option__theoretical {
  .option__odds {
    color: $color-primary;
    font-style: italic;
  }
}

.option__main {
  flex-grow: 1;
  line-height: normal;
  order: 3;
  padding-left: 2rem;
}
.option__main--single-column {
  padding-left: 0;
}

.option__name {
  flex-grow: 1;
  svg.trend-icon {
    width: 1.3em;
    height: 1.3em;
    margin-top: -0.3em;
    position: relative;
    top: 0.25em;
  }
}

.option__team {
  font-weight: 300;
  font-size: 1.2rem;
  font-style: italic;
  color: $color-body-light;
}

.option__existing-bet,
.option__potential-net {
  font-weight: 300;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  color: $color-neutral-3;
}

.option__potential-net--theoretical {
  font-style: italic;
}

.option__existing-bet + .option__potential-net {
  margin-top: 0;
}

.option__existing-bet-list {
  padding: 0;
  list-style: none;
  margin: 0.5rem 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    border-radius: 0.5rem;
    border: $color-panel-dark-mono 0.1rem solid;
    margin-bottom: 0.25rem;
    padding-left: 0;
    text-indent: 0;
    width: calc(50% - 0.5rem);
  }
}

.option__existing-bet-amount {
  background-color: $color-panel-dark-mono;
  color: white;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  text-align: center;
  width: 50%;
}

.option__existing-bet-odds {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  text-align: center;
  width: 50%;
}

.option__show-all-bets,
.option__show-all-bets:hover {
  color: $color-primary;
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  height: auto;
  margin: -1rem 0 1rem;
  padding: 0;
  text-align: right;
  text-decoration: none;
  width: 100%;
}

.option__checkbox {
  width: 4rem;
}

.bet-input {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  order: 2;
  padding-left: 2rem;
  width: 18rem;

  input {
    font-family: $font-display;
    font-size: 3rem;
    font-weight: 700;
    height: 8.1rem;
    margin-bottom: 0;
    text-align: right;
    width: calc(100% - 4.5rem);
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.bet-input__modifiers {
  display: flex;
  flex-direction: column;
}

button.bet-input__modifier {
  background: $color-primary;
  border: none;
  border-radius: 0;
  color: white;
  cursor: pointer;
  display: table-cell;
  font-size: 10px;
  font-weight: 300;
  height: 1.8rem;
  line-height: 1.5rem;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0.1rem 0 0 0;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 4rem;
  z-index: 10;

  &:active {
    top: 0.1rem;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    background: $color-primary-light;
  }

  &:disabled {
    background-color: $color-neutral-1 !important;
    cursor: default;
  }
  &.bet-input__modifier--clear {
    background: $color-cancel;
    &:hover {
      background: $color-cancel-light;
    }
  }
  &.bet-input__modifier--bet {
    background: $color-success;
    margin-bottom: 0;
    &:hover {
      background: $color-success-light;
    }
  }
}

.option--winner {
  color: $color-primary;
  svg {
    height: 0.8em;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .option__header {
    top: 0;
    .option__bet-input {
      display: none;
    }
  }

  @supports not (display: grid) {
    .option {
      display: block;
      .option__odds {
        float: left;
      }
      .bet-input,
      .option__main {
        padding-left: 1rem;
        margin-left: 6rem;
        width: calc(100% - 6rem);
      }
    }
  }

  .option {
    display: grid;
    grid-template-columns: 5rem auto;
    grid-template-rows: auto;
    grid-template-areas: "odds label" "odds input";
  }
  .option__odds {
    grid-area: odds;
    font-size: 1.6rem;
    margin-top: 0;
    margin-bottom: auto;
    width: 5rem;
    line-height: normal;
  }
  .option__main {
    grid-area: label;
  }

  .bet-input:before {
    content: "Enter a new bet:";
    display: block;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    font-weight: 700;
    width: 100%;
  }
  .bet-input {
    grid-area: input;
    display: block;
    width: 100%;
  }
  .bet-input input {
    text-align: left;
    height: 5rem;
    width: 100%;
    max-width: none;
  }
  .bet-input__modifiers {
    flex-direction: row;
    padding-top: 0.5rem;
  }
  button.bet-input__modifier {
    padding: 0.6rem 1rem;
    background: $color-primary;
    border-radius: 0.2rem;
    color: white;
    margin-right: 0.5rem;
    height: auto;
    margin-bottom: 0;
    flex-grow: 1;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  button.bet-input__modifier.bet-input__modifier--clear {
    background: $color-cancel;
    color: white;
  }
}
