.label--regular {
  font-weight: 300;
}

textarea {
  max-width: 62rem;
  margin-bottom: 1.5rem;
}

.form__row {
  display: flex;
  width: 100%;
  max-width: 62rem;
}

.form__col--50 {
  width: calc(50% - 1rem);
  &:nth-child(2n + 1) {
    margin-right: 2rem;
  }
}
@media only screen and (max-width: 600px) {
  .form__row {
    display: block;
  }
  .form__col--50 {
    width: 100%;
    &:nth-child(2n + 1) {
      margin-right: 0;
    }
  }
}

input[type="submit"]:disabled,
button:disabled {
  background-color: $color-neutral-1;
  border-color: $color-neutral-1;

  &:hover {
    background-color: $color-neutral-1;
    border-color: $color-neutral-1;
  }
}

input[type="submit"],
button {
  font-family: $font-sans;
  font-weight: 400;
  &.login__submit {
    display: block;
  }
  &.button--clear {
    display: block;
  }
  &:focus {
    outline: 0;
  }
}

.button--inline,
.button--inline:hover {
  background: transparent;
  color: $color-primary;
  display: inline;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  text-decoration: underline;
  text-transform: inherit;
  line-height: inherit;
  border: none;
}
.button--inline:hover {
  color: $color-primary;
}

.button--no-underline {
  text-decoration: none;
}

.button--hide {
  opacity: 0;
  cursor: default;
}

.button-group > a.button,
.button-group > button.button {
  &:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 0.1rem;
  }

  &:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media screen and (max-width: 500px) {
  .button-group {
    margin-bottom: 2rem;
  }

  .button-group > a.button,
  .button-group > button.button {
    width: 100%;
    margin-bottom: 0;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-left: 0;
      margin-top: 0.1rem;
    }

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:first-child {
      border-top-right-radius: 0.4rem;
    }
    &:last-child {
      border-bottom-left-radius: 0.4rem;
    }
  }
}

.login__error-message {
  color: $color-error;
  margin: 0 0 0.5rem;
}
.sidebar .login__error-message {
  padding: 0;
}

.login__submit--error {
  animation-name: shake;
  animation-duration: 0.5s;
  animation-iteration-count: 2;
}

.login__forget {
  color: $color-body;
  font-size: 1rem;
  text-decoration: none;

  &:hover {
    color: $color-primary;
  }
}

.login__forget--active,
.login__forget--active:hover {
  color: $color-body;
  cursor: default;
}

input[type="submit"] {
  display: block;
  margin-top: 1rem;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.1rem, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(0.2rem, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-0.4rem, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(0.4rem, 0, 0);
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  max-width: 30rem;
}

input[type="text"] + .input-helper,
input[type="password"] + .input-helper,
input[type="email"] + .input-helper,
input[type="text"] + .input-error,
input[type="password"] + .input-error,
input[type="email"] + .input-error {
  margin-top: -1rem;
  max-width: 30rem;
}
textarea + .input-helper,
textarea + .input-error {
  margin-top: -1.5rem;
  max-width: 62rem;
}

.input-helper {
  color: $color-body-light;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 2rem;
}

.input-error {
  color: $color-error;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 1rem;
}

input.text-input--error {
  border: 0.2rem solid $color-error;
}

/*
 * Checkbox and radio inputs
 * Borrowed from https://jsfiddle.net/purplebabar/1mx35bxc/7/
 */

[type="checkbox"],
[type="radio"] {
  position: absolute;
  opacity: 0;
}
[type="checkbox"] + label,
[type="radio"] + label {
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  display: inline-block;
}
[type="checkbox"] + label:before,
[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
  border: 0.1rem solid $color-border;
}

[type="radio"] + label:before,
[type="radio"] + label:after {
  border-radius: 50%;
}
[type="checkbox"]:checked + label:after,
[type="radio"]:checked + label:after {
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  background: $color-primary;
  position: absolute;
  top: 0.7rem;
  left: 0.5rem;
}
[type="radio"]:disabled + label:before,
[type="checkbox"]:disabled + label:before {
  background-color: $color-border;
}
[type="radio"]:disabled + label,
[type="checkbox"]:disabled + label {
  color: $color-border;
}
[type="radio"]:disabled:checked + label:after,
[type="checkbox"]:disabled:checked + label:after {
  background: $color-primary-light;
}

.new-player-form__h4 {
  margin-top: 4rem;
  bottom: 1rem;
}
.new-player__newsletters {
  margin-top: 4rem;
  max-width: 62rem;
}
input.new-player__submit {
  margin-top: 4rem;
}

.react-tags__search input {
}
