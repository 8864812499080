$sidebar-width: 250px;

#root {
  position: relative;
}

#root > main {
  max-width: 100rem;
  position: relative;
  left: $sidebar-width;
  width: calc(100vw - #{$sidebar-width});
  min-height: calc(100vh - #{$footer-height} - #{$footer-margin});
  & > section {
    padding: calc(#{$main-padding} * 0.5 + #{$banner-height}) $main-padding
      $main-padding;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    &.fade-enter,
    &.fade-enter-active,
    &.fade-exit,
    &.fade-exit-active {
      position: absolute;
    }
    &.fade-enter-done {
      position: relative;
    }
  }
}
#root > .alert + main > section {
  padding: $main-padding;
}

.sidebar {
  width: $sidebar-width;
  height: 100vh;
  overflow-x: visible;
  overflow-y: scroll;
  position: fixed;
}

.alert {
  background: $color-panel-dark;
  color: white;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;

  &--primary-color {
    background: $color-primary;
  }

  .button--inline,
  a {
    color: white;
    text-decoration: underline;
  }
  input {
    background: white;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
}

#root > .alert + main {
  top: 0;
}

// For use at the top of the page. Padding and margins account for banner and sidebar.
.alert--full-width {
  padding: 8rem 4rem 2rem;
  position: relative;
  left: $sidebar-width;
  width: calc(100% - #{$sidebar-width});
}
