.home-welcome {
  h1 {
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 2rem;
    line-height: normal;
  }
  a {
    color: white;
    text-decoration: underline;
  }
}

.home-welcome--prop-page {
  h1 { font-size: 1.6rem;}
  p {
    font-size: 1.6rem;
  }
}

.home-welcome__h1--secondary {
  font-weight: 400;
}
.home-welcome__h1 + p {
  margin-top: 0;
}

p.home-welcome__login {
  margin-top: -1.5rem;
}
a.home-welcome__login-link {
  font-size: 1rem;
  text-decoration: none;
  color: #ccc
}

@media only screen and (max-width: $mobile-breakpoint) {
  .home-welcome {
    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1.6rem;
    }
  }
}
