
/* Correction to space the checkbox on Scratch listings, because label size is larger */
// [type="checkbox"] + label:before, [type="radio"] + label:before {
// 	top: 0.6rem;
// }

// [type="checkbox"]:checked + label:after, [type="radio"]:checked + label:after {
// 	top: 1.1rem;
// }

.option__scratch-reason-input input,
.option__scratch-reason {
	margin-bottom: 0;
}
