$card-padding: 1rem;

.proposition-menu {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
}

.proposition-menu__h2 {
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  margin: 0 auto 2rem;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 80%;
  a {
    text-decoration: none;
    color: $color-body;
    &:hover {
      color: $color-primary;
    }
  }

  span {
    background: white;
    padding: 0 1rem;
    display: inline-block;
    position: relative;
    font-size: 1.4rem;
    line-height: 1;
  }
  span:before,
  span:after {
    content: "";
    border-top: 1px solid;
    width: 1000px;
    position: absolute;
    border-top: 1px solid $color-rule;
    top: 0.45rem;
  }
  span:before {
    right: 100%;
  }
  span:after {
    left: 100%;
  }
  &:before,
  &:after {
    content: "";
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background: $color-rule;
    position: absolute;
    top: 0.35rem;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}

.proposition-menu__h2--space-above {
  margin-top: 8rem;
}

.proposition-menu__h3 {
  border-bottom: 0.1rem solid $color-rule;
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

@media only screen and (max-width: 400px) {
  .proposition-menu__h2 {
    width: 100%;
    letter-spacing: 0.1rem;
  }
  .proposition-menu__h3 {
    letter-spacing: 0.1rem;
  }
}

.prop-card {
  background: white;
  box-shadow: 0.2rem 0 1rem rgba(0, 0, 0, 0.2);
  display: block;
  margin-bottom: 2.5rem;
  padding-bottom: 4rem;
  padding-top: $card-padding;
  position: relative;
  text-decoration: none;
  width: calc((1 / 3) * 100% - (4rem / 3));
  padding-bottom: 11rem;

  &.prop-card--logged-in {
    padding-bottom: 13rem;
  }
  &:not(:nth-child(3n)) {
    margin-right: 2rem;
  }
  &:hover {
    background-color: $color-panel;

    .prop-card__race {
      color: $color-primary;
    }
    .prop-card__button {
      animation: none;
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  &:active:hover {
    top: 0.2rem;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  }
}

.proposition-menu__h2--featured {
  max-width: 400px;
}
.proposition-menu--featured {
  .prop-card {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 9rem;
  }
  .prop-card__question {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 1000px) {
  .prop-card {
    width: calc(50% - 1rem);

    &:not(:nth-child(3n)) {
      margin-right: 0;
    }
    &:nth-child(2n + 1) {
      margin-right: 2rem;
    }
  }
}

@media only screen and (max-width: 500px) {
  .proposition-menu {
    display: block;
  }
  .prop-card {
    width: 100%;
    margin-right: 0;
  }
}

.prop-card__alert {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background: $color-primary;
  padding: 0.25 0.5;
  position: relative;
  top: -1rem;
}

.prop-card__race {
  margin: 0 $card-padding 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: $color-body;
}

.prop-card__question {
  font-size: 2rem;
  margin: 0 $card-padding 2rem;
  color: $color-body;
}

.prop-card__meta {
  bottom: 4rem;
  color: $color-body-light;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.2rem;
  line-height: normal;
  margin-bottom: -0.2rem;
  padding: 0;
  position: absolute;
  width: 100%;

  strong {
    display: block;
    border-bottom: 0.25rem;
    font-size: 0.8rem;
    line-height: 1.6;
    text-transform: uppercase;
  }
  .prop-card__meta-item {
    width: 50%;
    padding: 0 1rem 1rem;
  }
  .prop-card__meta-item--full-width {
    width: 100%;
  }
  .prop-card__meta-item--alert {
    color: $color-error;
    strong {
      color: $color-body-light;
    }
  }
}

@media only screen and (min-width: 800px) {
  .proposition-menu--featured .prop-card .prop-card__meta-item {
    width: 25%;
  }
  .proposition-menu--featured .prop-card:not(.prop-card--logged-in) {
    padding-bottom: 8rem;
    .prop-card__meta-item {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 799px) {
  .proposition-menu--featured .prop-card {
    padding-bottom: 11rem;
  }
  .proposition-menu--featured .prop-card--logged-in {
    padding-bottom: 13rem;
  }
}

@media only screen and (min-width: 375px) and (max-width: $mobile-breakpoint) {
  .prop-card {
    padding-bottom: 9rem;
  }
  .prop-card--logged-in {
    padding-bottom: 11rem;
  }
}

.prop-card__button {
  @extend button;
  border-radius: 0;
  bottom: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  animation: buttonPulse 20s infinite;

  &:not(.button--inline):active {
    position: absolute;
    bottom: 0;
    top: inherit;
  }

  &.prop-card__button--inactive {
    animation: none;
    background: #444;
    border: 0.1rem solid #444;
  }
}

@keyframes buttonPulse {
  0% {
    background-color: $color-success;
  }

  45% {
    background-color: $color-success;
  }
  50% {
    background-color: darken($color-success, 15%);
  }

  55% {
    background-color: $color-success;
  }

  100% {
    background-color: $color-success;
  }
}

.proposition-menu__sections {
  display: flex;
}

.proposition-menu__section {
  width: calc(50% - 2rem);

  &:first-child {
    margin-right: 4rem;
  }
}

.winner-loser {
  flex-wrap: wrap;
}

.winner-loser__bet-list {
  list-style: none;
  width: 100%;
  margin: 0.5rem 0 0 2rem;
}

.winner-loser__bet-item {
  text-indent: -0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.4rem;
  padding-left: 0;
}

.winner-loser__bet-item--winner {
  font-weight: 700;
}

.winner-loser__show-more,
.winner-loser__show-more:hover {
  text-decoration: none;
  color: $color-body-light;
}

@media only screen and (max-width: 600px) {
  .proposition-menu__sections {
    display: block;
  }
  .proposition-menu__section {
    width: 100%;

    &:first-child {
      margin-right: 0;
      margin-bottom: 4rem;
    }
  }
}

.proposition-menu--grid {
  margin-bottom: 8rem;
}

.proposition-menu__list-item {
  list-style: none;
  line-height: normal;
  padding-left: 0;
  text-indent: 0;

  a {
    color: $color-body;
    text-decoration: none;
  }
}
