
// Base
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// Set box-sizing globally to handle padding and border widths
*,
*:after,
*:before
	box-sizing: inherit

// The base font-size is set at 62.5% for having the convenience
// of sizing rems in a way that is similar to using px: 1.6rem = 16px
html
	box-sizing: border-box
	font-size: 62.5%
	margin: 0
	scroll-behavior: smooth

// Default body styles
body
	color: $color-body
	font-family: $font-sans
	font-size: 1.6em // Currently ems cause chrome bug misinterpreting rems on body element
	font-weight: 300
	letter-spacing: .01em
	line-height: 1.6
	margin: 0
