$sidebar-padding: 2rem;

.sidebar {
  background-color: $color-panel;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  color: $color-body;
  display: flex;
  flex-direction: column;
  padding-top: calc(#{$banner-height} + #{$banner-margin});
  transition: padding 0.25s;
  z-index: 100;

  p {
    padding-left: $sidebar-padding;
    padding-right: $sidebar-padding;
  }
}

.banner--scrolled + .sidebar {
  padding-top: calc(#{$banner-height-scrolled} + #{$banner-margin});
}

.sidebar__login-form {
  padding-left: $sidebar-padding;
  padding-right: $sidebar-padding;
}

.sidebar__list {
  margin: 0;
}

.sidebar__item {
  list-style: none;
  width: 100%;
  margin: 0;
  padding-left: 0;
  text-indent: 0;
}

.sidebar__link,
button.sidebar__link--button {
  background-color: transparent;
  border: none;
  color: $color-body;
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  height: auto;
  letter-spacing: 0.1rem;
  line-height: normal;
  padding: 0.4rem $sidebar-padding;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  margin: 0;

  svg {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0.5rem;
    position: relative;
    top: 0.2rem;
  }

  &:visited {
    color: $color-body;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.sidebar__link.sidebar__link--active,
.sidebar__link.sidebar__link--active:hover {
  color: $color-primary;
}

.sidebar__link.sidebar__link--active:hover {
  background: transparent;
  cursor: default;
}

.sidebar__name {
  margin: 0 $sidebar-padding;
  line-height: 1;
}

.sidebar__points-label {
  font-size: $font-small;
  margin: 0 0 0.25rem;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.1rem;
  text-shadow: none;
}

.sidebar__points {
  background: $color-gray-dark;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 7rem;
  line-height: 1;
  margin: 0 $sidebar-padding 0.5rem;
  padding: 1rem;
  position: relative;
  text-align: center;
  text-shadow: 0 0 0.3em #81b3eb;
  letter-spacing: -0.5rem;
  overflow: hidden;
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cpattern%20id%3D%22a%22%20patternUnits%3D%22userSpaceOnUse%22%20width%3D%225%22%20height%3D%225%22%20patternTransform%3D%22rotate(45)%22%3E%3Cpath%20stroke%3D%22%23000%22%20d%3D%22M1%200v5%22%2F%3E%3C%2Fpattern%3E%3C%2Fdefs%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22url(%23a)%22%2F%3E%3C%2Fsvg%3E");
    content: "";
    opacity: 0.2;
    z-index: 5;
  }
  div,
  span {
    position: relative;
    z-index: 20;
  }
  &-total {
    font-weight: 700;
  }
  &.sidebar__points--5-digits {
    .sidebar__points-total {
      font-size: 6rem;
    }
  }
}

.sidebar__points--fade-enter.sidebar__points {
  animation: neon 1s ease-in-out infinite alternate;
  animation-iteration-count: 2;
}
.sidebar__points--fade-exit,
.sidebar__points--fade-exit--active,
.sidebar__points--fade-exit--done {
  display: none;
}

.sidebar__points-meta {
  font-size: $font-small;
  display: flex;
  text-transform: uppercase;
  letter-spacing: normal;
  text-shadow: none;
  color: #ccc;
  z-index: 10;
  margin-top: 0.3rem;
  &-item {
    width: 50%;
    padding: 0 0.25rem;
    line-height: 1.4;
  }
  & &-label {
    font-size: 0.9rem;
    display: block;
  }
}

.sidebar__divider {
  margin: 1rem 0;
}

.terms {
  flex-grow: 1;
  position: relative;
}
.terms__ul {
  bottom: 0;
  flex-grow: 1;
  font-size: 1rem;
  list-style: none;
  margin-bottom: 0;
  padding: 1rem $sidebar-padding;
  position: absolute;
  li {
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    text-indent: 0;
  }
  a {
    color: $color-body;
    text-decoration: none;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .sidebar__divider {
    margin: 1rem 0;
  }
  .sidebar__link,
  button.sidebar__link--button {
    padding: 0.5rem $sidebar-padding;
  }
  .sidebar {
    display: block;
  }
  .terms__ul {
    position: relative;
  }
  .sidebar__points {
    font-size: 5rem;
    letter-spacing: 0;
  }
  .sidebar__divider {
    margin: 0.5rem 0;
  }
  .sidebar__item--slack {
    display: none; // Just not enought room
  }
  .sidebar__points-meta  .sidebar__points-meta-label {
    font-size: $font-small;
    display: inline;
  }
}

@media only screen and (max-width: $mobile-breakpoint) and (min-height: 560px) {
  .sidebar__link,
  button.sidebar__link--button {
    padding: 0.5rem $sidebar-padding;
  }
}

@media only screen and (max-width: $mobile-breakpoint) and (min-height: 610px) {
  .sidebar__link,
  button.sidebar__link--button {
    padding: 1rem $sidebar-padding;
  }
}

@keyframes neon {
  to {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff,
      0 0 40px $color-primary, 0 0 70px $color-primary, 0 0 80px $color-primary,
      0 0 100px $color-primary, 0 0 150px $color-primary;
  }
  from {
    text-shadow: 0 0 0 0 #fff, 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff,
      0 0 20px $color-primary, 0 0 35px $color-primary, 0 0 40px $color-primary,
      0 0 50px $color-primary, 0 0 75px $color-primary;
  }
}
