.axis path {
  stroke: $color-rule;
}

.score-history--makePNG .axis {
  font-size: 1.6rem;
}

.axis-label {
  font-size: 1.4rem;
  font-weight: 700;
}
.axis-label--small {
  font-size: 1rem;
  font-weight: 300;
}

.tick line {
  stroke: $color-rule;
}
.tick text {
  fill: $color-body;
}

.bar rect {
  fill: $color-rule;
}

.bar--primary rect {
  fill: $color-primary;
}
.bar--secondary rect {
  fill: $color-success;
}

.bar text {
  fill: #fff;
  font: 1rem $font-sans;
}

.area {
  fill: $color-primary-lighter;
}

.area--secondary-lighter {
  fill: $color-success-lighter;
}
.area--cancel-lighter {
  fill: $color-cancel-lighter;
}
.area--cancel-light {
  fill: $color-cancel-light;
}
.area--cancel {
  fill: $color-cancel;
}

.line {
  fill: none;
}
.line--neutral {
  stroke: $color-rule;
}
.line--primary {
  stroke: $color-primary;
}
.line--secondary {
  stroke: $color-success;
}
.line--dotted {
  stroke-dasharray: 1, 1;
}
.line--hovered {
  pointer-events: none;
}

.legend--horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  *:not(:last-child) {
    margin-right: 1rem;
  }
}
.legend-item {
  font-size: 1.2rem;
  display: flex;
  line-height: 1;
  margin-bottom: 0.5rem;
  &:before {
    content: "";
    display: block;
    height: 1.2rem;
    width: 1.2rem;
    background: $color-success;
    margin-right: 0.5rem;
  }
}

.legend-item--line {
  &:before {
    content: "";
    display: block;
    height: 0.2rem;
    width: 1.2rem;
    position: relative;
    top: 0.5rem;
  }
}

.legend-item--primary {
  &:before {
    background: $color-primary;
  }
}
.legend-item--secondary-lighter {
  &:before {
    background: $color-success-lighter;
  }
}
.legend-item--border-secondary {
  &:before {
    border: 1px solid $color-success;
  }
}

.bet-history {
  overflow: visible;
  max-height: 80vh;
}
.bet-history__outer {
  position: relative;
  margin-bottom: 4rem;
}

.bet-history__tooltip {
  background: $color-primary;
  color: white;
  display: block;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-style: italic;
  padding: 0.5rem 1rem;
  pointer-events: none;
  position: absolute;
  left: 6rem;
  text-align: left;
  top: calc(100% - 6.6rem);
  width: calc(100% - 6rem);
  &--loser {
    background: $color-cancel;
  }
  &.hide {
    display: none;
  }
  &--bottom {
    top: auto;
    bottom: 4rem;
  }
}

// For the rank and payout histories on propositions
.history__outer {
  position: relative;
}
.history__tooltip {
  padding: 0.3rem 0.5rem;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  right: 0.5rem;
  border: 1px solid $color-rule;
  text-align: right;
  pointer-events: none;
  max-width: 75%;
  text-shadow: 1px 1px 0 white;
  &.hide {
    display: none;
  }
  &:hover {
    cursor: default;
  }
}
.history {
  overflow: visible;
}

.score-history--makePNG {
  .legend {
    margin-bottom: 1rem;
  }
  .legend-item {
    font-size: 2.4rem;
    margin-right: 4rem;
    font-weight: 700;
    &:before {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 1rem;
    }
  }
  .legend-item--line {
    &:before {
      height: 0.2rem;
      top: 0.9rem;
    }
  }
}

.chart-label--svg {
  font-size: 1.2rem;
  fill: $color-body;
  font-weight: 700;
  font-family: $font-sans;
}

.annotation-group {
  pointer-events: none;
}

.annotation-note {
  font-family: $font-sans;
  color: $color-body !important;
  fill: $color-body !important;
  font-size: 1rem;
  text-shadow: 2px 2px 0px white;
}

.bet-history .annotation-note-bg {
  fill: white;
  fill-opacity: 0.9;
}
.bet-history .annotation-note-label,
.bet-history .annotation-note-title {
  fill: $color-body !important;
}

.histogram__leader {
  pointer-events: all;
}
.histogram__leader .connector-dot {
  fill: $color-primary;
}
.histogram__leader .annotation-note-label {
  fill: $color-primary;
  pointer-events: all;
}
.histogram__leader .annotation-note-label:hover {
  cursor: pointer;
}
