.leaderboard-intro {
  margin-bottom: 4rem;
}

.loan-icon {
  height: 1em;
  margin-right: 0.3rem;
  position: relative;
  top: 0.2rem;
}

.wap-icon {
  height: 1em;
  margin-right: 0.3rem;
  position: relative;
  top: 0.2rem;
}
.player__wap-icon {
  top: 0;
  margin-right: 0.1rem;
}

.leader-notes li {
  list-style: none;
  margin-bottom: 0.5rem;
  padding-left: 0;
  text-indent: 0;
  display: flex;
  line-height: normal;
  svg {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.leaders-histogram {
  position: relative;
  margin-bottom: 4rem;
  .legend {
    background: rgba(255, 255, 255, 0.9);
    right: 0.5rem;
    padding: 0.5rem;
    position: absolute;
    top: 0.5rem;
  }
  svg {
    left: 0;
    position: relative;
    top: 0;
  }
}
