.transaction__row--profit {
  // font-weight: 700;
}

.leaders {
  th:not(:first-child),
  td:not(:first-child) {
    width: calc((100% - 20rem) / 6);
  }
  th svg.icon-sort {
    position: relative;
    top: 0.4rem;
    height: 2rem;
    width: 2rem;
  }
}


.sticky-table-outer {
  overflow-x: scroll;
}
.table--sticky {
  position: relative;
  z-index: 1;
  overflow-x: scroll;

  td,
  th {
    min-width: 10rem;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    z-index: 5;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 95%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 20rem;
    padding-right: 1rem;
    top: 0;
    left: 0;
  }
}

.table--rank {
  th:first-child {
    padding-left: 3.5rem;
  }
}

.rank-row--self {
  td,
  td:first-child {
    background: $color-primary;
    color: white;
    a {
      color: white;
    }
    &:last-child {
      padding-right: 0.3rem;
    }
  }
}

.rank-cell {
  display: flex;
  a {
    text-decoration: none;
  }
  .rank-cell__rank {
    width: 4rem;
    padding-right: 0.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
  }
  .rank-cell__name {
    flex-grow: 1;
  }
}

.transaction-table.table--sticky {
  td,
  th {
    width: calc((100% - 30rem) / 3);
  }

  td:first-child,
  th:first-child {
    width: 40rem;
  }
}

.th--sortable {
  &:not(.th--active):hover {
    color: $color-primary;
    cursor: pointer;
  }
}

.proposition-menu--archive 
 

{
  a {
    text-decoration: none;
  }
  .proposition-grid__prop {
    width: 40%;
  }
  .proposition-grid__date {
    width: 20%
  }
  .proposition-grid__winner {
    width: 40%
  }
}
.proposition-menu--archive-with-action {
  .proposition-grid__prop {
    width: 40%;
  }
  .proposition-grid__date {
    width: 15%
  }
  .proposition-grid__winner {
    width: 35%
  }
  .proposition-grid__action {
    width: 10%
  }
}



@media only screen and (max-width: $mobile-breakpoint) {
  table {
    td,
    th {
      padding: 0.4rem 0.6rem;
    }
    font-size: 1.4rem;
  }
  .leaders {
    th:not(:first-child),
    td:not(:first-child) {
      width: calc((100% - 15rem) / 6);
    }
  }

  .table--sticky {
    th:first-child,
    td:first-child {
      width: 12rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .transaction-table.table--sticky {
    td,
    th {
      width: auto;
      min-width: 0;
    }

    td:first-child,
    th:first-child {
      width: 15rem;
      min-width: 15rem;
    }
  }
  .table--sticky {
    .proposition-grid__prop {
      width: 15rem;
      min-width: 15rem;
    }
    .proposition-grid__date,
    .proposition-grid__winner,
    .proposition-grid__action {
      width: auto;
    }
  }

}



