.league-top-section{
  margin-bottom: 5rem;
}

.league-list {
  columns: 3;
  li.league-list__item {
    display: inline-block;
    list-style: none;
    width: 100%;

    a {
        text-decoration: none;
    }
  }
  li.league-list__item--owner {
      font-weight: 700;
  }
}

@media only screen and (max-width: 700px) {
  .league-list {
    columns: 2;
  }
}

@media only screen and (max-width: 700px) {
  .league-list {
    columns: 1;
  }
}
