footer {
    background-color:$color-panel-dark;
    bottom: 0;
    color: white;
    font-size: 0.8rem;
    line-height: 1;
    height: $footer-height;
    margin-top: $footer-margin;
    padding: 0.4rem 2rem 0.4rem calc(#{$sidebar-width} + #{$main-padding});
    position: relative;
    width: 100%;
    z-index: 1;
    a, a:hover {
        color: white;
        text-decoration: none;
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    footer {
        padding: 0.4rem 2rem;
    }
}